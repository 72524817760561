html {
  box-sizing: border-box;
}

*, *::before, *::after{
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
}

div.root {
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #CCCCCC73;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #004E98;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3371AD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.recharts-wrapper svg{
overflow: inherit !important;
}